
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import { Watch } from 'vue-property-decorator';
import { AppUserClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class WelcomeMiddleware extends Vue {

    selectedLanguage: string = "";
    availableLanguages: OM.TextIdentifier[] = [];

    activeSection: string = "welcome";
    nextRoute: string = "";

    created() {
        this.nextRoute = this.$route.query.link ? this.$route.query.link.toString() : '';

        this.selectedLanguage = store.state.loggedUser.preferredCulture;
        this.availableLanguages = this.$localizationService.getEnabledLanguageList();
    }

    mounted() {
        gigya.accounts.showScreenSet({
            screenSet: "Aprilia-2024-Community-RegistrationLogin",
            containerID: "gigya-login-one-column",
            startScreen: "gigya-login-one-column"
        });

        gigya.accounts.addEventHandlers({
            onLogin: function(ris) {
                AppUserClient.checkIfAlreadyRegisterByEmail(ris.profile.email)
                .then(x => {
                    var languageCredential = new OM.LanguageCredential();
                    languageCredential.preferredCulture = "it-IT";
                    languageCredential.username = ris.profile.email;
                    languageCredential.password = "123456";

                    if(x) {
                        AppUserClient.login(languageCredential)
                        .then(x => {
                            AppUserClient.updateUserType("secretPWd07072024", x.identifier, ris.data.FO_Bearacer)
                            .then(voidRis => {
                                LoginServices.LoginCallback(x.token, x.refreshToken, this.selectedLanguage);
                            })
                        })
                    }
                    else {
                        var updateModel = new OM.UpdateUserInfoVM();
                        updateModel.email = ris.profile.email;
                        updateModel.name = ris.profile.firstName;
                        updateModel.surname = ris.profile.lastName;
                        updateModel.id = ris.profile.UID;
                        updateModel.type = ris.data.FO_Bearacer;
                        LoginServices.RegisterNewUser(languageCredential, updateModel, false);
                    }
                })
            }
        });
    }

    get registerLink() {
        if(this.selectedLanguage == "it-IT")
            return "https://www.aprilia.com/it_IT/bearacerclub-registration/";
        else
            return "https://www.aprilia.com/en_EN/bearacerclub-registration/";
    }

    @Watch('selectedLanguage')
    updateLanguage() {
        this.setLanguage();
    }

    setLanguage(){
        var loggedUser = store.state.loggedUser;
        loggedUser.preferredCulture = this.selectedLanguage;

        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

}
